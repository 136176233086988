@font-face {
  font-family: "Lazydog";
  src: url("./fonts/lazydog.otf");
}

* {
  font-family: 'Lazydog', sans-serif !important;
  letter-spacing: -0.05em; 

}

html, body, #root {
  height: 100% !important;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

::-webkit-scrollbar {
  display: none;
}

.chakra-ui-light {
  min-height: 100% !important;
  height: 100% !important;
  overflow: hidden;
}




.view {
  background-color: transparent;
  width: 80%;
  margin: 0 auto;


  max-width: 1300px;
  
}

.gameView {

  margin: 0 auto;
  width: 80%;
}

.squareTop {
  display: flex;
  width: 50%;
  margin: 0 auto;
}

.square {
  position: relative;
  width: 40%;
  background-image: url("media/background.jpeg");
  background-size: cover;
  margin: 0 auto;
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
}

.verticalAlign {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100% !important;
    height: 100%;
}

.css-1bsg0uq {
  opacity: 1;
}

.logo {

  width: 40%;
  margin: 0 auto;
  margin-bottom: 1.5em;
}

.menu {
  width: 40%;
  margin: 0 auto;
  margin-bottom: 1.5em;
}

.leaderboard {
  width: 90%;
  
  margin: 0 auto;
}

@media only screen and (min-width: 700px) and (max-width: 1000px)
{

}

@media only screen and (min-width: 0px) and (max-width: 700px)
{
  .logo {
    width: 80%;
  }

  .menu {
    width: 80%;
  }

  .view {
    width: 100%;
  }

  .gameView {
    width: 90%;
  }
  .squareTop {
    width: 90%;
  }
  .square {
    position: relative;
    width: 90%;
    margin: 0 auto;
  }
}

@keyframes fadeIn {
  0% {
      opacity: 0;
      
    }
   
    100% {
      opacity: 1;
      
    }
}


#play, #leaderboard, #buyAmem, #app, #leaderboardButton, #exclusive, #notAHolder {
  -webkit-animation: 2s ease 0s normal forwards 1 fadeIn;
  animation: 2s ease 0s normal forwards 1 fadeIn;
  opacity: 0;
}